@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
  html {
    @apply font-sans;
  }
}
.flip-card {
  perspective: 1000px;
  position: relative;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: translateZ(0);
  will-change: transform;
  transform-origin: center;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front {
  z-index: 1;
}

.flipped .flip-card-front {
  z-index: 0;
}

.flipped .flip-card-back {
  z-index: 1;
}


/* 
@layer base {
  body {
    @apply text-body text-neutral-600 leading-[2.1em] font-light;
  }
  
} */

.no-scroll {
  overflow: hidden;
}

@layer base {
  section {
    @apply bg-slate-50;
  }
}

.gm-style-iw-chr {
  position: absolute;
  display: flex;
  bottom: 30px;
  right: 2px;
}

.gm-style-iw.gm-style-iw-c {
  padding: 24px !important;
}

.slick-slider .slick-dots {
  bottom: -6px;
}

.slick-slider .slick-arrow:before {
  color: #363D5A !important;
}